import { Button, Close } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import { colors } from "../theme"
import useModal from "./useModal"

interface IProps {
  title: string
  message: string
  onConfirm: (params?: any) => void
  onCancel: (params?: any) => void
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  max-width: 350px;
  box-shadow: 0 5px 0 #afafaf, 0 9px 0 rgba(0, 0, 0, 0.4);
  > * {
    margin-bottom: 12px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: ${colors.indigo};
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 25px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
`

const useConfirmModal = (props: IProps) => {
  const modal = useModal()

  const open = (params?: any) => {
    const m = modal.open(
      <Overlay>
        <Wrapper>
          <div className="title">{props.title}</div>
          <div className="message">{props.message}</div>
          <Actions>
            <Button theme="confirm" size="small" onClick={() => {
              props.onConfirm(params)
              modal.close()
            }}>
              Yes
            </Button>
            <Button theme="red" size="small" onClick={() => {
              props.onCancel(params)
              modal.close()
            }}>
              No
            </Button>
          </Actions>
          <CloseButton>
            <Button
              theme="red"
              size="small"
              round
              onClick={() => modal.close(m)}
            >
              <Close />
            </Button>
          </CloseButton>
        </Wrapper>
      </Overlay>
    )
  }

  return open
}

export default useConfirmModal
