import React, { useMemo } from 'react'
import styled from 'styled-components';
import { IconButton, Pencil, Plus, Refresh, TrashWhite } from 'nzk-react-components';
import { navigate } from 'gatsby';
import useData from '../data/AssignmentsLineup/useData';
import Table from '../../../TableV2';
import useAsync from '../../../../hooks/useAsync';
import useConfirmModal from '../../../../hooks/useConfirmModal';

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  > * { margin-right: 10px; }
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const EducationCategoriesPage = () => {
  const { fetchEducationCategories, deleteEducationCategory } = useData()

  const { data: categories, loading, execute: fetch } = useAsync({
    asyncFunc: async (params) => {
      return fetchEducationCategories({
        skip: params.skip,
        limit: params.limit
      }, params.refetch)
    },
    funcParams: {
      skip: 0,
      limit: 0,
      refetch: false
    },
    immediate: true,
    initialData: []
  })

  const confirmDelete = useConfirmModal({
    title: "Are you sure?",
    message:
      "Do you want to delete this education category? This is going to affect all lineups linked to this.",
    onConfirm: async (id) => {
      await deleteEducationCategory(id)
      fetch({ refetch: true })
    },
    onCancel: () => {},
  })

  const formattedCategories = useMemo(() => {
    return categories
  }, [categories])

  const columns = useMemo(() => {
    return [
      {
        'Header': 'Title',
        'accessor': 'title',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <div>{row.original.title || 'Not set'}</div>
      },
      {
        'Header': 'Description',
        'accessor': 'description',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <div>{row.original.description || 'Not set'}</div>
      },
      {
        'Header': 'Actions',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Actions>
          <IconButton size='x-small' theme='red' icon={<TrashWhite />} onClick={() => confirmDelete(row.original._id)}>Delete</IconButton>
          <IconButton size='x-small' theme='orange' icon={<Pencil />} onClick={() => navigate(`/star-tutoring/education-categories/${row.original._id}/edit`)}>Edit</IconButton>
        </Actions>
      },
    ]
  }, [formattedCategories])

  return <Wrapper>
    <Header>
      <IconButton size='x-small' theme='primary' icon={<Refresh />} onClick={() => fetch({ refetch: true })}>Refresh</IconButton>
      <IconButton size='x-small' theme='confirm' icon={<Plus />} onClick={() => navigate('/star-tutoring/education-categories/create')}>Create</IconButton>
    </Header>
    <Table columns={columns} data={formattedCategories} loading={loading} />
  </Wrapper>
}

export default EducationCategoriesPage
