import { useContext } from 'react'
import ModalContext from '../components/Context/ModalContext'

const useModal = () => {
  const modal = useContext(ModalContext)
  return {
    ...modal
  }
}

export default useModal
